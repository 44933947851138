<template>
  <div :key="counst">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main content-auto">
        <ayl-table-with-query-object :table="table">
          <div slot="ctrl-button">
            <el-button type="primary" @click="onSearch">查询</el-button>
          </div>
        </ayl-table-with-query-object>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;
    return {
      counst: 0,
      nav: [{ name: "交单审核" }],
      table: {
        api: vm.$api.OrderList,
        query: {
          type: 3,
          begin: new Date().setTime(
            new Date(new Date().setMonth(new Date().getMonth() - 1))
          ),
          end: new Date().getTime(),
          queryContent: null,
          carBlong: null,
          orderType: null,
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "dateSection_zl",
            title: "运输日期",
            // 是否需要快捷选项
            isOptions: true,
            model: [],
            modelName: ["begin", "end"],
          },
          {
            type: "select",
            title: "业务类型",
            option: this.$enums.OrderType.list,
            // 是否需要快捷选项
            model: "orderType",
          },
          {
            type: "select",
            title: "车辆归属",
            option: this.$enums.carOwnership.list,
            // 是否需要快捷选项

            model: "carBlong",
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "客户名称/驾驶员/提货车号/省市区",
          },
        ],
        columns: [
          //   {
          //   title: '序号',
          //   width: '50px',
          //   $index: 'index',
          // },
          {
            title: "客户名称",
            key: "partnerName",
            width: "140px",
            fixed: "left",
            showTooltip: true,
          },
          // {
          //   title: "结算客户",
          //   key: "partnerAccountName",
          //   width: "140px",
          //   fixed: "left",
          //   showTooltip: true,
          // },
          {
            title: "运输日期",
            key: "transportTime",
            fixed: "left",
            width: "100px",
            filter: "str2ymd",
          },
          {
            title: "业务类型",
            fixed: "left",
            width: "90px",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    style: {
                      color:
                        ctx.row.orderType === "直拨"
                          ? vm.$config.color_z
                          : ctx.row.orderType === "内转"
                          ? vm.$config.color_n
                          : vm.$config.color_w,
                    },
                  },
                  ctx.row.orderType || "- -"
                ),
              ]);
            },
          },
          {
            title: "驾驶员",
            fixed: "left",
            width: "90px",
            key: "driverName",
          },
          {
            title: "提货车号",
            width: "100px",
            fixed: "left",
            key: "plateNumber",
          },
          {
            title: "车辆归属",
            width: "90px",
            fixed: "left",
            key: "carBelongDes",
          },
          {
            title: "起运地",
            key: "sendAddressDetail",
            showTooltip: true,
          },
          {
            title: "收货地（省市区）",
            width: '180px',
            key: "receiveProvinceName",
            showTooltip: true,
          },
          {
            title: "目的地",
            key: "receiveAddressDetail",
            showTooltip: true,
          },
          {
            title: "",
            width: "30px",
            align: "center",
            render: (h, ctx) => {
              return h("div", [
                h(
                  "el-button",
                  {
                    props: {
                      plain: true,
                      size: "mini",
                      icon: ctx.row.checkRemark?"el-icon-s-flag": "",
                      circle: true
                    },
                    style: {
                      color: "#f44",
                      border: "none",
                      fontSize: "16px",

                      backgroundColor: 'rgba(0,0,0,0)'
                    },

                  },
                ),
              ]);
            },
          },
          
          {
            title: "核算备注",
            key: "checkRemark",
            showTooltip: true,
          },
          {
            title: "订单编号",
            key: "orderId",
            width: "180px",
            // showTooltip: true,
          },
          {
            title: "操作",
            width: "90px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view": true,
                    },
                    on: {
                      click: vm.goExamine.bind(this, ctx.row),
                    },
                  },
                  "审核"
                ),
                // h('span', {
                //   class: {
                //     'table-view-line': true,
                //   },
                //   on: {
                //     // click: vm.goEdit.bind(this, ctx.row)
                //   }
                // }, '编辑'),
                // h('span', {
                //   class: {
                //     'table-delete': true
                //   },
                //   on: {
                //     // click: vm.delete.bind(this, ctx.row)
                //   }
                // }, '删除')
              ]);
            },
          },
        ],
      },
      list: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/examine/examine-details") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  async activated() {
    if (!this.$route.meta.isBack) {
      this.counst++;
    } else {
      await this.$search(this.table);
      this.$route.meta.isBack = false; // 重置详情页标识isBack
    }
  },
  watch: {
    async $route() {
      await this.$search(this.table);
    },
  },
  methods: {
    tableRowClassName(row,rowIndex) {
      console.log(row)
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    async onSearch() {
      await this.$search(this.table);
    },
    goExamine(e) {
      this.$router.push({
        path: "/examine/examine-details",
        query: {
          orderId: e.orderId,
        },
      });
    },
  },
  async mounted() {
    await this.$search(this.table);
  },
};
</script>

<style lang='sass' scoped>
</style>
